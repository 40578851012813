export default {
  APP_NAME: "ELITE Manager",
  EMAIL: "Courriel",
  PASSWORD: "Mot de passe",
  SIGN_IN: "Connexion",
  FORGOT_PASSWORD: "Mot de passe oublié?",
  PAGE_NOT_FOUND: "La page demandée n'a pas été trouvée",
  RETURN_HOME: "Retour a l'accueil",
  WELCOME: "Bienvenu",
  MY_PROFILE: "Mon profil",
  LOGOUT: "Déconnexion",
  DASHBOARD: "Tableau de bord",
  USERS: "Utilisateurs",
  ROLES: "Rôles",
  ORGANIZATIONS: "Organisations",
  ORGANIZATION: "Organisation",
  MY_ORGANIZATION: "Mon organisation",
  PRODUCTS: "Produits",
  PRODUCT: "Produit",
  ALL: "Tout",
  NAME: "Nom",
  ADDRESS: "Adresse",
  PHONE: "Téléphone",
  CREATED_AT: "Créé le",
  VALIDATED_AT: "Validée le",
  VALIDATE: "Valider",
  YES_VALIDATE: "Oui, valider",
  YES_ASSIGN: "Oui, assigner",
  SEARCH: "Recherche",
  PER_PAGE: "Par page",
  VIEW: "Voir",
  EDIT: "Modifier",
  DELETE: "Supprimer",
  YES_DELETE: "Oui, supprimer",
  DISPLAY_FROM_X_TO_X_OF_X_ENTRIES:
    "Affichage de {from} à {to} sur {of} entrées",
  X_LINES_SELECTED: "{count} lignes sélectionnées",
  RETURN_TO_LIST: "Retour à la liste",
  SELECT_IMAGE: "Choisir une image",
  CHANGE_IMAGE: "Changer l'image",
  REMOVE_IMAGE: "Supprimer l'image",
  ROLE: "Rôle",
  FIRSTNAME: "Prénom",
  LASTNAME: "Nom",
  CONFIRM_PASSWORD: "Confirmer le mot de passe",
  RESELLERS: "Revendeurs",
  RESELLER: "Revendeur",
  LOCALE: "Langue",
  LOCALE_FR: "Français",
  LOCALE_EN: "English",
  GLOBAL: "Résumé",
  NONE: "Aucun",
  UPDATED_AT: "Mis à jour le",
  PERMISSIONS: "Permissions",
  ALL_ORGANIZATIONS: "Toutes les organisations",
  ALL_RESELLERS: "Tous les revendeurs",
  ALL_ROLES: "Tous les rôles",
  ALL_USERS: "Tous les utilisateurs",
  ALL_INSPECTIONS: "Toutes les inspections",
  ALL_PRODUCTS: "Tous les produits",
  EXCERPT: "Description",
  OWNER: "Propriétaire",
  USER: "Utilisateur",
  YES: "Oui",
  NO: "Non",
  OK: "OK",
  OR: "Ou",
  PICTURE: "Photo",
  GALLERY: "Gallérie",
  TAXES: "Taxes",
  TAX_GROUP: "Groupe de taxes",
  TAX_GROUPS: "Groupes de taxes",
  PRICE: "Prix",
  RATE: "Taux (%)",
  CODE: "Code",
  DEFAULT_TAXES: "Taxes par défaut",
  INVOICES: "Factures",
  INVOICE: "Facture",
  RECIPIENT: "Destinataire",
  COUNTRY: "Pays",
  COUNTRIES: "Pays",
  STATES: "Régions",
  STATE: "Région",
  CITY: "Ville",
  ZIPCODE: "Code postal",
  NOTE: "Note",
  STATUS: "Statut",
  ALL_STATUSES: "Tous les statuts",
  AMOUNT: "Montant",
  PAIED: "Payé",
  TOTAL: "Total",
  TOTAL_PAIED: "Total payé",
  CREATED_BY: "Créé par",
  DETAILS: "Détails",
  PAYMENTS: "Paiements",
  UNIT_PRICE: "Prix unitaire",
  SUBTOTAL: "Sous-total",
  QUANTITY: "Quantité",
  DISCOUNT: "Réduction",
  REMAINING_PAYMENT_AMOUNT: "Balance",
  DISCOUNT_PERCENT: "Réduction (%)",
  CLOSE: "Fermer",
  CUSTOMER: "Client",
  CUSTOMERS: "Clients",
  ALL_CUSTOMERS: "Tous les clients",
  LOGS: "Journaux",
  REQUEST_LOGS: "Appels API",
  CAUSER: "Auteur",
  DATE: "Date",
  GO_BACK: "Retour",
  PACKAGE: "Forfait",
  PACKAGES: "Forfaits",
  ALL_PACKAGES: "Tous les forfaits",
  SUBSCRIPTION: "Inscription",
  SUBSCRIPTIONS: "Inscriptions",
  TYPE: "Type",
  TAX_TYPE_PERCENTAGE: "Pourcentage",
  TAX_TYPE_AMOUNT: "Montant",
  PAYMENT: "Paiement",
  ALL_INVOICES: "Toutes les factures",
  DISCOUNTS: "Réductions",
  DISCARD_CHANGES_AND_LEAVE_PAGE: "Abandonner les modifications et quitter?",
  COMPANY_NAME: "Nom de l'entreprise",
  BILLING_CUSTOMER_TYPE_INDIVIDUAL: "Particulier",
  BILLING_CUSTOMER_TYPE_COMPANY: "Entreprise",
  REQUESTS: "Demandes",
  REQUEST: "Demande",
  INSPECTION: "Inspection",
  INSPECTIONS: "Inspections",
  INSPECTION_FORM: "Formulaire d'inspection",
  INSPECTION_FORMS: "Formulaires",
  ALL_INSPECTION_FORMS: "Tous les formulaires",
  EXPAND_ALL: "Tout déplier",
  COLLAPSE_ALL: "Tout replier",
  PREVIOUS: "Précédent",
  NEXT: "Suivant",
  SUBMIT: "Soumettre",
  RESET: "Réinitialiser",
  BILLING_ENTITY_TYPE_INDIVIDUAL: "Particulier",
  BILLING_ENTITY_TYPE_COMPANY: "Entreprise",
  CONTACTS: "Contacts",
  CONTACT: "Contact",
  SUPPLIER: "Fournisseur",
  SCHOOL: "Ecole",
  TITLE: "Titre",
  TITLES: "Titres",
  ALL_TITLES: "Tous les titres",
  TITLE_MR: "Monsieur",
  TITLE_MDM: "Madame",
  TAG: "Étiquette",
  TAGS: "Étiquettes",
  ADD_TAG: "Ajouter une étiquette",
  BIRTHDAY: "Date d’anniversaire",
  SUPPLIERS: "Fournisseurs",
  FISCAL_NUMBER: "Numéro fiscale",
  ADD: "Ajouter",
  ADDED: "Ajouté",
  UPDATE: "Modifier",
  UPDATED: "Modifié",
  COLOR: "Couleur",
  LINK_CONTACT: "Lier le contact",
  CONTINUE: "Continuer",
  ADD_CONTACT: "Ajouter un contact",
  SOURCE: "Source",
  LOCATIONS: "Concessions",
  LOCATION: "Concession",
  ALL_LOCATIONS: "Tous les concessions",
  FILES: "Fichiers",
  FILE: "Fichier",
  ALL_FILES: "Tous les fichiers",
  FOLDERS: "Dossiers",
  FOLDER: "Dossier",
  ALL_FOLDERS: "Tous les dossiers",
  FILES_EXPLORER: "Explorateur",
  WAREHOUSE: "Entrepôt",
  WAREHOUSES: "Entrepôts",
  PURCHASES_ORDER: "Commande achat",
  PURCHASES_ORDERS: "Commandes achat",
  ISSUER: "Émetteur",
  PURCHASES_INVOICE: "Facture achat",
  PURCHASES_INVOICES: "Factures achat",
  PURCHASES_DELIVERY: "Livraison achat",
  PURCHASES_DELIVERIES: "Livraisons achat",
  PURCHASES_PAYMENT: "Paiement achat",
  PURCHASES_PAYMENTS: "Paiements achat",
  SALES_ORDER: "Commande vente",
  SALES_ORDERS: "Commandes vente",
  SALES_INVOICE: "Facture vente",
  SALES_INVOICES: "Factures vente",
  SALES_DELIVERY: "Livraison vente",
  SALES_DELIVERIES: "Livraisons vente",
  SALES_PAYMENT: "Paiement vente",
  SALES_PAYMENTS: "Paiements vente",
  BILLING_INFORMATIONS: "Information de facturation",
  BILLING_ADDRESS: "Adresse de facturation",
  RESELLER_PRODUCTS: "Produits revendeur",
  RESELLER_SERVICES: "Services revendeur",
  RESELLER_INVOICE: "Facture revendeur",
  RESELLER_INVOICES: "Factures revendeur",
  RESELLER_PAYMENTS: "Paiements revendeur",
  SERVICES: "Services",
  SERVICE: "Service",
  RESELLER_PRODUCT: "Produit revendeur",
  RESELLER_SERVICE: "Service revendeur",
  APP: "Applications",
  APP_: "Applications",
  DELIVERY_ADDRESS: "Adresse de livraison",
  OTHER_PHONES: "Autres téléphones",
  EXTENSION: "Extension",
  ALL_SUPPLIERS: "Tous les fournisseurs",
  PHONE_TYPE_MOBILE: "Mobile",
  PHONE_TYPE_HOME: "Domicile",
  PHONE_TYPE_OFFICE: "Bureau",
  PHONE_TYPE_BUSINESS: "Professionnel",
  PHONE_TYPE_FAX: "Fax",
  PHONE_TYPE_PAGER: "Bipeur",
  CONFIRMATION: "Confirmation",
  PHONE_TYPE_OTHER: "Autre",
  SELLING_PRICE: "Prix de vente",
  BUYING_PRICE: "Prix d'achat",
  DESTINATION_WAREHOUSE: "Entrepot de destination",
  SOURCE_WAREHOUSE: "Entrepot source",
  STOCK_MOVEMENT: "Mouvement de stock",
  STOCK_MOVEMENTS: "Mouvements de stock",
  EXPECTED_QUANTITY: "Quantité attendue",
  DELIVERED_QUANTITY: "Quantité livrée",
  SAVE: "Enregistrer",
  SUCCESS_VERIFICATION:
    "Félicitations ! Vous avez réussi à vérifier votre code de sécurité.",
  ERROR_VERIFICATION: "Le code de vérification est erroné",
  VERIFICATION_CODE: "Code de vérification",
  DELIVERY_INFORMATIONS: "Informations livraison",
  RESEND_CODE: "Renvoyez le code",
  ENABLE_2FA:
    "Souhaitez-vous activer la vérification en deux étapes pour sécuriser davantage votre compte?",
  DISABLE_2FA:
    "Souhaitez-vous désactiver la vérification en deux étapes et revenir à la connexion sans 2FA?",
  IMPORTS: "Imports",
  IMPORT: "Import",
  MY_LOCATIONS: "Mes concessions",
  NEXT_STEP: "Étape suivante",
  PREV_STEP: "Étape précédente",
  CANCEL: "Annuler",
  YES_CANCEL: "Oui, annuler",
  DOWNLOAD_CSV_FILE: "Télécharger le fichier csv",
  RETURN_TO_LOGIN: "Retour à la connexion",
  RESET_PASSWORD_SENT:
    "Un e-mail contenant un lien de réinitialisation du mot de passe a été envoyé.",
  RESET_PASSWORD_USER_NOT_FOUND:
    "Nous ne trouvons pas d'utilisateur avec cette adresse e-mail.",
  SEND_PASSWORD_RESET_LINK:
    "Envoyer le lien de réinitialisation du mot de passe",
  RESET_PASSWORD: "Réinitialiser le mot de passe",
  PASSWORD_UPDATED: "Mot de passe mis a jour",
  TOKEN_ERROR:
    "Token de changement de mot de passe invalide merci de réessayer",
  EMAIL_ERROR: "Email invalide merci de réessayer",
  VEHICLE: "Véhicule",
  VEHICLES: "Véhicules",
  ESTIMATE: "Estimation",
  ESTIMATION: "Estimation",
  ESTIMATES: "Estimations",
  ESTIMATIONS: "Estimations",
  VEHICLE_ESTIMATIONS_DOMAINS: "Domaine autorisé pour les appels api",
  DOMAIN: "Domaine",
  DOMAINS: "Domaines",
  ALL_DOMAINS: "Tous les domaines",
  IP_ADDRESS: "Adresse IP",
  VEHICLE_ESTIMATIONS: "Évaluations",
  PICTURES: "Photos",
  REFRESH: "Actualiser",
  PRIORITY: "Priorité",
  MAIN_PICTURE: "Photo principale",
  START_TYPING_CITY_REGION_COUNTRY:
    "Commencez par saisir la ville, la région ou le pays pour completer l'adresse",
  MANAGER: "Gestionnaire",
  COMMENTS: "Commentaires",
  NEW_COMMENT: "Nouveau commentaire",
  WRITE_COMMENT: "Écrire votre commentaire",
  RESPONSE_READY: "Réponse prête",
  STATUS_CODE: "HTTP status code",
  ERROR: "Erreur",
  TODO: "À faire",
  LOW: "Bas",
  HIGH: "Haut",
  TO: "À",
  WHOLESALE: "Wholesale",
  DETAIL: "Détail",
  COEFFICIENT: "Coefficient",
  BY: "par",
  ENABLE_DEBUG_MODE: "Activer le mode debug",
  REMINDER: "Rappel",
  PARTICULAR: "Particulier",
  COMPANY: "Entreprise",
  RESOURCE_ID: "La resource a l’id",
  THE_REQUESTS: "La demande",
  THE_INSPECTIONS: "L’inspection",
  THE_REPAIRS: "La réparation",
  THE_INSPECTION_FORMS: "Le formulaire d’inspection",
  THE_VEHICLES: "Le véhicule",
  THE_RESELLERS: "Le Revendeur",
  THE_EVALUATIONS: "L’estimation",
  THE_ESTIMATIONS: "L’estimation",
  THE_REQUEST_LOGS: "L’appel api",
  THE_CONTACTS: "Le contact",
  THE_CUSTOMERS: "Le clients",
  THE_SALES_ORDERS: "La commande de vente",
  THE_SALES_INVOICES: "La facture de vente",
  THE_SALES_PAYMENTS: "Le paiement de vente",
  THE_SALES_DELIVERIES: "La livraison de vente",
  THE_SUPPLIERS: "Le fournisseur",
  THE_PURCHASES_ORDERS: "La commande d’achat",
  THE_PURCHASES_INVOICES: "La facture d’achat",
  THE_PURCHASES_PAYMENTS: "Le paiement d’achat",
  THE_PURCHASES_DELIVERIES: "La livraison d’achat",
  THE_PRODUCTS: "le produit",
  THE_STOCK_MOVEMENTS: "Le mouvement de stock",
  THE_WAREHOUSE: "L’entrepôt",
  THE_RESELLER_INVOICES: "La facture de revendeur",
  THE_RESELLER_PAYMENTS: "Le paiement de revendeur",
  THE_RESELLER_PRODUCTS: "Le produit de revendeur",
  THE_RESELLER_SERVICES: "Le service de revendeur",
  THE_PACKAGES: "Le forfait",
  THE_SUBSCRIPTIONS: "L’inscription",
  THE_LOCATIONS: "La concession",
  THE_ORGANIZATIONS: "l’organisation",
  THE_USERS: "L’utilisateur",
  THE_ROLES: "Le role",
  THE_PERMISSIONS: "La permission",
  THE_IMPORTS: "L’import",
  LOADING: "Chargement",
  END: "Terminer",
  YES_END: "Oui, terminer",
  OTHERS: "Autres",
  ALL_OF: "Tous les {name}",
  YES_START: "Oui, démarrer",
  YES_TAKE_OVER: "Oui, Se l’approprier",
  INTERNAL: "Interne",
  ADD_INTERNAL: "Ajouter un interne",
  APPROVER: "Approbateur",
  APPROVAL: "Approbation",
  RESPONSIBLE: "Responsables",
  REMOVE: "Retirer",
  IMPORTANT: "Important",
  SHOW: "Voir",
  DRAFTS: "Brouillons",
  IN_PROGRESS: "En cours",
  PENDING: "En attentes",
  TODO: "À faire",
  CREATOR: "Créateur",
  ALL_USERS_WITH_ROLE: "Tous les utilisateurs avec le rôle: {name}",
  SEARCH_MANAGER: "Rechercher un responsable",
  CHANGE_MANAGER: "Changer de responsable",
  ENTER_MANAGER_NAME: "Entrer le nom du responsable",
  SAVE_BTN: "Sauvegarder",
  COUNTRY_CODE: "Code pays",
  SELECT_COUNTRY: "Choisir un pays",
  PHONE_NUMBER: "Numéro de téléphone",
  EXAMPLE: "Ex",
  HIDDEN: "Cacher",
  LOAD_MORE: "Charger plus",
  VIEW_MORE: "Voir plus",
  MECANIC: "Mécanicien",
  OPTIONS: "Paramètres",
  DELETE: "Effacer",
  COPIED: "Copié!!",
  COPY: "Copier",
  REPAIRS: "Réparations",
  COMPLETED: "Complétées",
  CANCELED: "Annulées",
  REPAIR: "Réparation",
  REPAIRS: "Réparations",
  SHARE: "Partager",
  SYSTEM_OBJECT: "Objet système",
  SEND: "Envoyer",
  DAYS: "Jour(s)",
  MODIFY: "Modification",
  TASK: "Tâche",
  PENDING_APPROVE: "À approuver",
  APPROVED: "Approuvée",
  DENIED: "Refusée",
  REVIEWED: "À reviser",
  PARTIALLY_APPROVED: "Partiellement approuvée",
  CHOOSE: "Choisir",
  SEND_INVITATION: "Envoyer une invitation",
  INVITATION_SENT: "Invitation envoyée",
  WORK_ORDER: "Bon de travail",
  ENTER_WORK_ORDER: "Entrer le bon de travail",
  RESULT: "Résultat",
  ODOMETER_FROM: "Odomètre de",
  ODOMETER_TO: "à",
  SOFTWARE: "Les logiciels",
  OTHER_LOGIN: "Autres connexions",
  LOGIN_WITH_ACCOUNT:
    "Veuillez vous connecter pour continuer avec votre compte.",
  SHOW_DELETED_ITEMS: "Afficher les éléments supprimés",
};
