export default {
  RESELLERS_LIST: "Reseller List",
  ADD_RESELLER: "Add Reseller",
  RESELLER_ADDED: "Reseller Added",
  EDIT_RESELLER: "Edit Reseller",
  RESELLER_UPDATED: "Reseller Updated",
  DELETE_THIS_RESELLER: "Delete this Reseller?",
  RESELLER_DELETED: "Reseller Deleted",
  CONFIG_MANAGER: "Manager Configuration",
  CONFIG_MANAGER_APP_LOGO: "App Logo",
  CONFIG_MANAGER_APP_NAME: "App Name",
  CONFIG_MANAGER_URL_REGEX: "URL Regex",
  VIEW_RESELLER: "View Reseller",
};