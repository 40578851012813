export default {
  APP_NAME: "ELITE Manager",
  EMAIL: "Email",
  PASSWORD: "Password",
  SIGN_IN: "Sign In",
  FORGOT_PASSWORD: "Forgot Password?",
  PAGE_NOT_FOUND: "Page Not Found",
  RETURN_HOME: "Return to Home",
  WELCOME: "Welcome",
  MY_PROFILE: "My Profile",
  LOGOUT: "Logout",
  DASHBOARD: "Dashboard",
  USERS: "Users",
  ROLES: "Roles",
  ORGANIZATIONS: "Organizations",
  ORGANIZATION: "Organization",
  MY_ORGANIZATION: "My Organization",
  PRODUCTS: "Products",
  PRODUCT: "Product",
  ALL: "All",
  NAME: "Name",
  ADDRESS: "Address",
  PHONE: "Phone",
  CREATED_AT: "Created At",
  VALIDATED_AT: "Validated At",
  VALIDATE: "Validate",
  YES_VALIDATE: "Yes, validate",
  YES_ASSIGN: "Yes, assign",
  SEARCH: "Search",
  PER_PAGE: "Per Page",
  VIEW: "View",
  EDIT: "Edit",
  DELETE: "Delete",
  YES_DELETE: "Yes, delete",
  DISPLAY_FROM_X_TO_X_OF_X_ENTRIES: "Displaying {from} to {to} of {of} entries",
  X_LINES_SELECTED: "{count} lines selected",
  RETURN_TO_LIST: "Return to List",
  SELECT_IMAGE: "Select Image",
  CHANGE_IMAGE: "Change Image",
  REMOVE_IMAGE: "Remove Image",
  ROLE: "Role",
  FIRSTNAME: "First Name",
  LASTNAME: "Last Name",
  CONFIRM_PASSWORD: "Confirm Password",
  RESELLERS: "Resellers",
  RESELLER: "Reseller",
  LOCALE: "Locale",
  LOCALE_FR: "Français",
  LOCALE_EN: "English",
  GLOBAL: "Overview",
  NONE: "None",
  UPDATED_AT: "Updated At",
  PERMISSIONS: "Permissions",
  ALL_ORGANIZATIONS: "All Organizations",
  ALL_RESELLERS: "All Resellers",
  ALL_ROLES: "All Roles",
  ALL_USERS: "All Users",
  ALL_INSPECTIONS: "All Inspections",
  ALL_PRODUCTS: "All Products",
  EXCERPT: "Description",
  OWNER: "Owner",
  USER: "User",
  YES: "Yes",
  NO: "No",
  OK: "OK",
  OR: "Or",
  PICTURE: "Picture",
  GALLERY: "Gallery",
  TAXES: "Taxes",
  TAX_GROUP: "Tax Group",
  TAX_GROUPS: "Tax Groups",
  PRICE: "Price",
  RATE: "Rate (%)",
  CODE: "Code",
  DEFAULT_TAXES: "Default Taxes",
  INVOICES: "Invoices",
  INVOICE: "Invoice",
  RECIPIENT: "Recipient",
  COUNTRY: "Country",
  COUNTRIES: "Countries",
  STATES: "States",
  STATE: "State",
  CITY: "City",
  ZIPCODE: "Zip Code",
  NOTE: "Note",
  STATUS: "Status",
  ALL_STATUSES: "All Statuses",
  AMOUNT: "Amount",
  PAIED: "Paid",
  TOTAL: "Total",
  TOTAL_PAIED: "Total Paid",
  CREATED_BY: "Created By",
  DETAILS: "Details",
  PAYMENTS: "Payments",
  UNIT_PRICE: "Unit Price",
  SUBTOTAL: "Subtotal",
  QUANTITY: "Quantity",
  DISCOUNT: "Discount",
  REMAINING_PAYMENT_AMOUNT: "Balance",
  DISCOUNT_PERCENT: "Discount (%)",
  CLOSE: "Close",
  CUSTOMER: "Customer",
  CUSTOMERS: "Customers",
  ALL_CUSTOMERS: "All Customers",
  LOGS: "Logs",
  REQUEST_LOGS: "API Logs",
  CAUSER: "Causer",
  DATE: "Date",
  GO_BACK: "Go Back",
  PACKAGE: "Package",
  PACKAGES: "Packages",
  ALL_PACKAGES: "All Packages",
  SUBSCRIPTION: "Subscription",
  SUBSCRIPTIONS: "Subscriptions",
  TYPE: "Type",
  TAX_TYPE_PERCENTAGE: "Percentage",
  TAX_TYPE_AMOUNT: "Amount",
  PAYMENT: "Payment",
  ALL_INVOICES: "All Invoices",
  DISCOUNTS: "Discounts",
  DISCARD_CHANGES_AND_LEAVE_PAGE: "Discard changes and leave page?",
  COMPANY_NAME: "Company Name",
  BILLING_CUSTOMER_TYPE_INDIVIDUAL: "Individual",
  BILLING_CUSTOMER_TYPE_COMPANY: "Company",
  REQUESTS: "Requests",
  REQUEST: "Request",
  INSPECTION: "Inspection",
  INSPECTIONS: "Inspections",
  INSPECTION_FORM: "Inspection Form",
  INSPECTION_FORMS: "Inspection Forms",
  ALL_INSPECTION_FORMS: "All Inspection Forms",
  EXPAND_ALL: "Expand All",
  COLLAPSE_ALL: "Collapse All",
  PREVIOUS: "Previous",
  NEXT: "Next",
  SUBMIT: "Submit",
  RESET: "Reset",
  BILLING_ENTITY_TYPE_INDIVIDUAL: "Individual",
  BILLING_ENTITY_TYPE_COMPANY: "Company",
  CONTACTS: "Contacts",
  CONTACT: "Contact",
  SUPPLIER: "Supplier",
  SCHOOL: "School",
  TITLE: "Title",
  TITLES: "Titles",
  ALL_TITLES: "All Titles",
  TITLE_MR: "Mr.",
  TITLE_MDM: "Mrs.",
  TAG: "Tag",
  TAGS: "Tags",
  ADD_TAG: "Add Tag",
  BIRTHDAY: "Birthday",
  SUPPLIERS: "Suppliers",
  FISCAL_NUMBER: "Tax ID",
  ADD: "Add",
  ADDED: "Added",
  UPDATE: "Update",
  UPDATED: "Updated",
  COLOR: "Color",
  LINK_CONTACT: "Link Contact",
  CONTINUE: "Continue",
  ADD_CONTACT: "Add Contact",
  SOURCE: "Source",
  LOCATIONS: "Dealerships",
  LOCATION: "Dealership",
  ALL_LOCATIONS: "All Dealerships",
  FILES: "Files",
  FILE: "File",
  ALL_FILES: "All Files",
  FOLDERS: "Folders",
  FOLDER: "Folder",
  ALL_FOLDERS: "All Folders",
  FILES_EXPLORER: "File Explorer",
  WAREHOUSE: "Warehouse",
  WAREHOUSES: "Warehouses",
  PURCHASES_ORDER: "Purchase Order",
  PURCHASES_ORDERS: "Purchase Orders",
  ISSUER: "Issuer",
  PURCHASES_INVOICE: "Purchase Invoice",
  PURCHASES_INVOICES: "Purchase Invoices",
  PURCHASES_DELIVERY: "Purchase Delivery",
  PURCHASES_DELIVERIES: "Purchase Deliveries",
  PURCHASES_PAYMENT: "Purchase Payment",
  PURCHASES_PAYMENTS: "Purchase Payments",
  SALES_ORDER: "Sales Order",
  SALES_ORDERS: "Sales Orders",
  SALES_INVOICE: "Sales Invoice",
  SALES_INVOICES: "Sales Invoices",
  SALES_DELIVERY: "Sales Delivery",
  SALES_DELIVERIES: "Sales Deliveries",
  SALES_PAYMENT: "Sales Payment",
  SALES_PAYMENTS: "Sales Payments",
  BILLING_INFORMATIONS: "Billing Information",
  BILLING_ADDRESS: "Billing Address",
  RESELLER_PRODUCTS: "Reseller Products",
  RESELLER_SERVICES: "Reseller Services",
  RESELLER_INVOICE: "Reseller Invoice",
  RESELLER_INVOICES: "Reseller Invoices",
  RESELLER_PAYMENTS: "Reseller Payments",
  SERVICES: "Services",
  SERVICE: "Service",
  RESELLER_PRODUCT: "Reseller Product",
  RESELLER_SERVICE: "Reseller Service",
  APP: "Applications",
  APP_: "Applications",
  DELIVERY_ADDRESS: "Delivery Address",
  OTHER_PHONES: "Other Phones",
  EXTENSION: "Extension",
  ALL_SUPPLIERS: "All Suppliers",
  PHONE_TYPE_MOBILE: "Mobile",
  PHONE_TYPE_HOME: "Home",
  PHONE_TYPE_OFFICE: "Office",
  PHONE_TYPE_BUSINESS: "Business",
  PHONE_TYPE_FAX: "Fax",
  PHONE_TYPE_PAGER: "Pager",
  CONFIRMATION: "Confirmation",
  PHONE_TYPE_OTHER: "Other",
  SELLING_PRICE: "Selling Price",
  BUYING_PRICE: "Buying Price",
  DESTINATION_WAREHOUSE: "Destination Warehouse",
  SOURCE_WAREHOUSE: "Source Warehouse",
  STOCK_MOVEMENT: "Stock Movement",
  STOCK_MOVEMENTS: "Stock Movements",
  EXPECTED_QUANTITY: "Expected Quantity",
  DELIVERED_QUANTITY: "Delivered Quantity",
  SAVE: "Save",
  SUCCESS_VERIFICATION:
    "Congratulations! You have successfully verified your security code.",
  ERROR_VERIFICATION: "The verification code is incorrect",
  VERIFICATION_CODE: "Verification Code",
  DELIVERY_INFORMATIONS: "Delivery Information",
  RESEND_CODE: "Resend Code",
  ENABLE_2FA:
    "Would you like to enable two-factor authentication to further secure your account?",
  DISABLE_2FA:
    "Would you like to disable two-factor authentication and return to login without 2FA?",
  IMPORTS: "Imports",
  IMPORT: "Import",
  MY_LOCATIONS: "My dealerships",
  NEXT_STEP: "Next Step",
  PREV_STEP: "Previous Step",
  CANCEL: "Cancel",
  YES_CANCEL: "Yes, cancel",
  DOWNLOAD_CSV_FILE: "Download CSV file",
  RETURN_TO_LOGIN: "Return to Login",
  RESET_PASSWORD_SENT: "An email with a password reset link has been sent.",
  RESET_PASSWORD_USER_NOT_FOUND:
    "We couldn't find a user with that email address.",
  SEND_PASSWORD_RESET_LINK: "Send password reset link",
  RESET_PASSWORD: "Reset Password",
  PASSWORD_UPDATED: "Password updated",
  TOKEN_ERROR: "Invalid password reset token. Please try again.",
  EMAIL_ERROR: "Invalid email. Please try again.",
  VEHICLE: "Vehicle",
  VEHICLES: "Vehicles",
  ESTIMATE: "Estimate",
  ESTIMATION: "Estimation",
  ESTIMATES: "Estimates",
  ESTIMATIONS: "Estimations",
  VEHICLE_ESTIMATIONS_DOMAINS: "Authorized domains for API calls",
  DOMAIN: "Domain",
  DOMAINS: "Domains",
  ALL_DOMAINS: "All Domains",
  IP_ADDRESS: "IP Address",
  VEHICLE_ESTIMATIONS: "Evaluations",
  PICTURES: "Pictures",
  REFRESH: "Refresh",
  PRIORITY: "Priority",
  MAIN_PICTURE: "Main Picture",
  START_TYPING_CITY_REGION_COUNTRY:
    "Start typing the city, region or country to complete the address",
  MANAGER: "Manager",
  COMMENTS: "Comments",
  NEW_COMMENT: "New Comment",
  WRITE_COMMENT: "Write your comment",
  RESPONSE_READY: "Response ready",
  STATUS_CODE: "HTTP status code",
  ERROR: "Error",
  TODO: "To do",
  LOW: "Low",
  HIGH: "High",
  TO: "To",
  WHOLESALE: "Wholesale",
  DETAIL: "Detail",
  COEFFICIENT: "Coefficient",
  BY: "by",
  ENABLE_DEBUG_MODE: "Enable debug mode",
  REMINDER: "Reminder",
  PARTICULAR: "Particular",
  COMPANY: "Company",
  RESOURCE_ID: "The resource has the id",
  THE_REQUESTS: "The request",
  THE_INSPECTIONS: "The inspection",
  THE_REPAIRS: "The repair",
  THE_INSPECTION_FORMS: "The inspection form",
  THE_VEHICLES: "The vehicle",
  THE_RESELLERS: "The Reseller",
  THE_EVALUATIONS: "The estimation",
  THE_ESTIMATIONS: "The estimation",
  THE_REQUEST_LOGS: "The API call",
  THE_CONTACTS: "The contact",
  THE_CUSTOMERS: "The customers",
  THE_SALES_ORDERS: "The sales order",
  THE_SALES_INVOICES: "The sales invoice",
  THE_SALES_PAYMENTS: "The sales payment",
  THE_SALES_DELIVERIES: "The sales delivery",
  THE_SUPPLIERS: "The supplier",
  THE_PURCHASES_ORDERS: "The purchase order",
  THE_PURCHASES_INVOICES: "The purchase invoice",
  THE_PURCHASES_PAYMENTS: "The purchase payment",
  THE_PURCHASES_DELIVERIES: "The purchase delivery",
  THE_PRODUCTS: "the product",
  THE_STOCK_MOVEMENTS: "The stock movement",
  THE_WAREHOUSE: "The warehouse",
  THE_RESELLER_INVOICES: "The reseller invoice",
  THE_RESELLER_PAYMENTS: "The reseller payment",
  THE_RESELLER_PRODUCTS: "The reseller product",
  THE_RESELLER_SERVICES: "The reseller service",
  THE_PACKAGES: "The package",
  THE_SUBSCRIPTIONS: "The subscription",
  THE_LOCATIONS: "The dealership",
  THE_ORGANIZATIONS: "the organization",
  THE_USERS: "The user",
  THE_ROLES: "The role",
  THE_PERMISSIONS: "The permission",
  THE_IMPORTS: "The import",
  LOADING: "Loading",
  END: "End",
  YES_END: "Yes, end",
  OTHERS: "Others",
  ALL_OF: "All {name}",
  YES_START: "Yes, start",
  YES_TAKE_OVER: "Yes, take over",
  INTERNAL: "Internal",
  ADD_INTERNAL: "Add internal",
  APPROVER: "Approver",
  APPROVAL: "Approval",
  RESPONSIBLE: "Responsible",
  REMOVE: "Remove",
  IMPORTANT: "Important",
  SHOW: "Show",
  DRAFTS: "Drafts",
  IN_PROGRESS: "In progress",
  PENDING: "Pending",
  TODO: "To do",
  CREATOR: "Creator",
  ALL_USERS_WITH_ROLE: "All users with role: {name}",
  SEARCH_MANAGER: "Search manager",
  CHANGE_MANAGER: "Change manager",
  ENTER_MANAGER_NAME: "Enter manager name",
  SAVE_BTN: "Save",
  COUNTRY_CODE: "Country code",
  SELECT_COUNTRY: "Select country",
  PHONE_NUMBER: "Phone number",
  EXAMPLE: "Ex",
  HIDDEN: "Hidden",
  LOAD_MORE: "Load more",
  VIEW_MORE: "View more",
  MECANIC: "Mechanic",
  OPTIONS: "Options",
  DELETE: "Delete",
  COPIED: "Copied!",
  COPY: "Copy",
  REPAIRS: "Repairs",
  COMPLETED: "Completed",
  CANCELED: "Canceled",
  REPAIR: "Repair",
  REPAIRS: "Repairs",
  SHARE: "Share",
  SYSTEM_OBJECT: "System object",
  SEND: "Send",
  DAYS: "Day(s)",
  MODIFY: "Modify",
  TASK: "Task",
  PENDING_APPROVE: "Pending approval",
  APPROVED: "Approved",
  DENIED: "Denied",
  REVIEWED: "Reviewed",
  PARTIALLY_APPROVED: "Partially approved",
  CHOOSE: "Choose",
  SEND_INVITATION: "Send invitation",
  INVITATION_SENT: "Invitation sent",
  WORK_ORDER: "Work order",
  ENTER_WORK_ORDER: "Enter work order",
  RESULT: "Result",
  ODOMETER_FROM: "Odometer from",
  ODOMETER_TO: "to",
  SOFTWARE: "Software",
  OTHER_LOGIN: "Other sing in options",
  LOGIN_WITH_ACCOUNT: "Please log in to continue with your account.",
  SHOW_DELETED_ITEMS: "Show deleted items",
};
